
















import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'
import { dateAdapter } from '@/apollo/adapters/modules'
import Link from '@/components/Link.vue'
import { TypePages } from '@/contracts/PageTypes'

export default defineComponent({
  name: 'CardDefault',
  components: {
    Link,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const store = useStore()
    const textLink = computed<string>(
      (): string => store.getters['settings/btnRead']
    )
    const fullUrl = computed<string>((): string => {
      const uniquePageUrls = store.getters['settings/uniquePageUrls']
      return uniquePageUrls[TypePages.news] + props.url
    })
    const adaptedDate = () => dateAdapter(props.date, true).replace(' г.', '')
    return {
      adaptedDate,
      fullUrl,
      textLink,
    }
  },
})
